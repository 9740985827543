<template>
  <div>
    <b-form v-on:submit.prevent="onSubmit" @reset="onReset" v-if="show">
      <b-card
        :header="$t('COMPANYFORM.COMPANYFORM1')"
        header-class="h4 font-weight-bold text-black-75"
      >
        <div class="d-flex py-lg-5">
          <b-card-text text-tag="h3"
            ><span class="svg-icon svg-icon-info svg-icon-2x mr-4">
              <inline-svg
                src="/media/svg/icons/Layout/Layout-top-panel-4.svg"
              />
            </span>
            {{ $t("COMPANY.REGISTRATION.GENERAL") }}
          </b-card-text>
        </div>

        <b-row>
          <b-col>
            <b-form-group
              id="input-group-1"
              label-for="input-1"
              :description="$t('COMPANYFORM.COMPANYFULLNAME3')"
            >
              {{ $t("COMPANYFORM.COMPANYFULLNAME1") }}
              <b-form-input
                id="input-1"
                v-model="form.org_name"
                required
                :placeholder="$t('COMPANYFORM.COMPANYFULLNAME2')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            {{ $t("COMPANYFORM.COMPANYREGNUM") }}
            <b-form-group id="input-group-5" label-for="input-5">
              <b-form-input
                id="input-5"
                v-model="form.org_number"
                required
                :placeholder="$t('COMPANYFORM.COMPANYREGNUM1')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            {{ $t("COMPANYFORM.COMPANYEMAIL") }}
            <b-form-group id="input-group-6" label-for="input-6">
              <b-form-input
                id="input-6"
                v-model="form.org_email"
                type="email"
                required
                :placeholder="$t('COMPANYFORM.COMPANYEMAIL1')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <!--  begin: Contacts Section  -->
        <div class="d-flex py-lg-5">
          <b-card-text class="mb-5" text-tag="h3"
            ><span class="svg-icon svg-icon-info svg-icon-2x mr-4">
              <inline-svg
                src="/media/svg/icons/Layout/Layout-top-panel-4.svg"
              />
            </span>
            {{ $t("COMPANY.REGISTRATION.CONTACT") }}
          </b-card-text>
        </div>

        <b-row>
          <b-col>
            {{ $t("COMPANYFORM.COMPANYPHONE") }}
            <b-form-group id="input-group-7" label-for="input-7">
              <b-form-input
                id="input-7"
                v-model="form.org_contact"
                required
                :placeholder="$t('COMPANYFORM.COMPANYPHONE1')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            {{ $t("COMPANYFORM.COMPANYADDRESS1") }}
            <b-form-group
              id="input-group-8"
              label-for="input-8"
              :description="$t('COMPANYFORM.COMPANYADDRESS13')"
            >
              <b-form-input
                id="input-8"
                v-model="form.add_1"
                required
                :placeholder="$t('COMPANYFORM.COMPANYADDRESS12')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            {{ $t("COMPANYFORM.COMPANYADDRESS2") }}
            <b-form-group
              id="input-group-9"
              label-for="input-9"
              :description="$t('COMPANYFORM.COMPANYADDRESS23')"
            >
              <b-form-input
                id="input-9"
                v-model="form.add_2"
                :placeholder="$t('COMPANYFORM.COMPANYADDRESS22')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            {{ $t("COMPANYFORM.COMPANYADDRESS3") }}
            <b-form-group
              id="input-group-10"
              label-for="input-10"
              :description="$t('COMPANYFORM.COMPANYADDRESS33')"
            >
              <b-form-input
                id="input-10"
                v-model="form.add_3"
                :placeholder="$t('COMPANYFORM.COMPANYADDRESS32')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            {{ $t("COMPANYFORM.COMPANYCITY1") }}
            <b-form-group
              id="input-group-12"
              label-for="input-12"
              :description="$t('COMPANYFORM.COMPANYCITY3')"
            >
              <b-form-input
                id="input-12"
                v-model="form.city_town"
                required
                :placeholder="$t('COMPANYFORM.COMPANYCITY2')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            {{ $t("COMPANYFORM.POSCODE1") }}
            <b-form-group
              id="input-group-13"
              label-for="input-13"
              :description="$t('COMPANYFORM.POSCODE3')"
            >
              <b-form-input
                id="input-13"
                v-model="form.postal_code"
                required
                :placeholder="$t('COMPANYFORM.POSCODE2')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            {{ $t("COMPANYFORM.STATE") }}
            <b-form-group id="input-group-14" label-for="input-14">
              <b-form-select
                id="input-14"
                v-model="form.state_id"
                :options="states"
                required
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <!--  end: Contacts Section  -->

        <b-card-footer footer-class="text-right">
          <div>
            <b-button type="reset" variant="light" size="lg" class="mr-2"
              >{{ $t("COMPANY.REGISTRATION.RESET") }}
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              size="lg"
              v-bind:class="{ 'spinner spinner-right spinner-light': formBusy }"
              >{{ $t("COMPANY.REGISTRATION.SUBMIT") }}
            </b-button>
          </div>
        </b-card-footer>
      </b-card>
    </b-form>
  </div>
</template>

<script>
import { GET_TYPE } from "@/modules/company/stores/registration/company_registration";
import swal from "sweetalert";

export default {
  name: "CompanyRegistrationForm",

  data: function () {
    return {
      states: [],
      form: {
        org_id: null,
        org_name: "",
        org_contact: null,
        org_number: "",
        org_email: "",
        add_1: "",
        add_2: "",
        add_3: "",
        city_town: "",
        postal_code: "",
        state_id: null,
        country_id: null,
      },
      formBusy: false,
      show: true,
    };
  },
  methods: {
    // to deserialize json to our need
    onCallStates() {
      console.log("Calling company type");
      this.$store.dispatch(GET_TYPE).then((res) => {
        console.log("Response:", res);
        this.states = res.states.map((value) => {
          return {
            value: value.id,
            text: value.name,
          };
        });
      });
    },
    fetchCompanyForm() {
      console.log("Fetching Company Form");
      this.$store.dispatch("fetchCompanyProfileForm").then((response) => {
        console.log(response);
        const formData = response.data.data;
        this.form = {
          org_id: formData.org_id,
          org_name: formData.org_name,
          org_contact: formData.org_phone,
          org_number: formData.org_no,
          org_email: formData.org_email,
          add_1: formData.address.add_1,
          add_2: formData.address.add_2,
          add_3: formData.address.add_3,
          city_town: formData.address.city_town,
          postal_code: formData.address.postal_code,
          state_id: formData.address.state_id,
          country_id: formData.address.country_id,
        };
        console.log("This is Updated Form", this.form);
      });
    },
    onSubmit() {
      console.log("start submit", this.form);
      this.formBusy = !this.formBusy;
      this.$store
        .dispatch("submitCompanyProfileForm", this.form)
        .then(() => {
          swal({
            title: "Company Profile Update Successfully",
            text: "Thank You For Updating",
            icon: "success",
          });
        })
        .catch((error) => {
          // Handles other than validation errors.
          if (!error.response.data.errors)
            swal(
              "Company Profile Update Failed",
              "Server Error detected.",
              "error"
            );
        })
        .finally(() => (this.formBusy = !this.formBusy));
    },
    onReset() {
      this.form = null;
    },
  },
  mounted() {
    this.onCallStates();
    this.fetchCompanyForm();
  },
};
</script>
